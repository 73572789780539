/* @include transform(normal_css_value_here) */
@mixin transform($value) {
	-webkit-transform: $value;
	-moz-transform: $value;
	-ms-transform: $value;
	-o-transform: $value;
	transform: $value;
}

/* @include transition(normal_css_value_here) */
@mixin transition($value) {
	-webkit-transition: $value;
	-moz-transition: $value;
	-ms-transition: $value;
	-o-transition: $value;
	transition: $value;
}

/* @include box-shadow(normal_css_value_here) */
@mixin box-shadow($value) {
	-webkit-box-shadow: $value;
	-moz-box-shadow: $value;
	-ms-box-shadow: $value;
	-o-box-shadow: $value;
	box-shadow: $value;
}

/* @include text-shadow(normal_css_value_here) */
@mixin text-shadow($value) {
	-webkit-text-shadow: $value;
	-moz-text-shadow: $value;
	-ms-text-shadow: $value;
	-o-text-shadow: $value;
	text-shadow: $value;
}

/* @include no-select() */
@mixin no-select() {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
