// IMPORT ADDONS
@import 'addons/bootstrap/bootstrap';
@import 'addons/font-awesome/font-awesome';
//@import 'addons/owl-carousel2/owl.carousel';
@import 'addons/layerslider/layerslider';
@import 'addons/magnific-popup/magnific-popup';
//@import 'addons/cube-portfolio/cubeportfolio';

// IMPORT TEMPLATE
@import 'functions';

/*****************************************************/

// FONTS
$font1: "Raleway";
$font2: "Montserrat";

/*****************************************************/

/* GENERAL TEMPLATE */
body {
	color: #000000;
	font-size: 10px;
	font-weight: 400;
	font-family: $font1;
	line-height: 100%;
	a {
		text-decoration: none !important;
		&:hover, &:focus {
			text-decoration: none !important;
		}
	}
	a[href^=tel], a[href^=mailto] {

		&:hover {

		}
	}
	h1.referencement {
		display: none !important;
	}
	.appear {
		opacity: 0;
		@include transition(opacity 0.75s ease-in);
	}
	.parallax, .parallax-slow, .parallax-fast {
		background-attachment: fixed;
		@media screen and (max-width: 768px) {
			background-attachment: scroll;
		}
	}
}

/*****************************************************/

.module-popup {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 20000; 
	display: block;
	position: fixed;
	.black-overlay {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1250;
		display: block;
		position: absolute;
		background: rgba(0, 0, 0, 0.75);
	}
	.popup-close {
		top: 15px;
		right: 15px;
		cursor: pointer;
		position: absolute;
	}
	.popup {
		top: 50%;
		left: 50%;
		z-index: 1255;
		display: table;
		max-width: 580px;
		position: absolute;
		border: 8px solid #fe0002;
		background-color: #fff;
		@include transform(translate(-50%, -50%))
		h3 {
			color: #fe0002;
			font-size: 3.6em;
			font-weight: 900;
			line-height: 140%;
			text-transform: uppercase;
		}
		p {
			padding: 10px;
			color: #0c0c0c;
			font-size: 20px;
			font-weight: 400;
			line-height: 140%;
			display: block;
		}
		img {
			margin: 0 auto;
		}
	}
	@media screen and (max-width:768px) {
		.popup {
			display: block;
			width: 100%;
	    max-width: 90%;
	    max-height: 75%;
			h3 {
				font-size: 24px;
			}
			p {
				font-size: 14px;
			}
		}
	}
}

/*****************************************************/

.module-menu-bar {
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	display: none;
	font-size: 10px;
	position: fixed;
	background: #fff;
	.logo {
		float: left;
    padding: 20px 15px;
		img {
			max-width: 50%;
		}
	}
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			float: left;
			color: #cbcbcb;
			font-size: 1.8em;
			padding: 5px;
			a {
				color: #7e7e7e;
				font-size: 0.95em;
				&.phone {
					color: #fe0002;
					font-size: 1.05em;
					font-family: $font2;
				}
			}
			&:hover a {
				color: #fe0002;
			}
		}
	}
	.top-right {
		top: 15px;
		right: 15px;
		position: absolute;
	}
	.bottom-right {
		right: 15px;
		bottom: 15px;
		position: absolute;
	}
	@media screen and (min-width: 768px) {
		font-size: 8px;
		display: block;
	}
	@media screen and (min-width: 1100px) {
		font-size: 10px;
		display: block;
		.logo {
			padding: 15px;
			img {
				max-width: 100%;
			}
		}
	}
}

/*****************************************************/

.module-menu-bar-mobile {
	top: 0;
	left: 0;
	right: 0;
	height: 60px;
	z-index: 999;
	position: fixed;
	background: #fff;
	img {
		width: 240px;
		padding-top: 10px;
		padding-left: 10px;
	}
	.menu-toggle {
		float: right;
		padding-top: 20px;
		padding-right: 20px;
		.bar {
			width: 30px;
			height: 3px;
			background: #000;
			&:nth-child(2) {
				margin: 4px 0;
			}
		}
	}
}
.module-menu-bar-mobile-overlay {
	top: 60px;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	font-size: 5px;
	display: block;
	position: fixed;
	background: #fff;
	@include transform(translateY(100%));
	@include transition(all 0.2s ease-out);
	ul {
		top: 50%;
		width: 100%;
		margin: 0;
		padding: 0;
		display: table;
		list-style: none;
		text-align: center;
		position: absolute;
		@include transform(translateY(-50%));
		li {
			font-size: 3em;
			font-weight: bold;
			font-family: $font2;
			line-height: 140%;
			padding: 5px 0;
			a {
				color: #fe0002;
			}
		}
	}
	&.toggled {
		@include transform(translateY(0%));
	}
	@media screen and (min-width: 768px) {
		font-size: 10px;
	}
}

/*****************************************************/

.module-slideshow {
	background: #fdfcfd;
	border-bottom: 5px solid #2e332d;
	.text {
		color: #323533;
		font-weight: 900;
		font-family: $font1;
		padding-left: 20px;
		border-left: 4px solid #2e332d;
		span {
			color: #fe0002;
		}
	}
	&.header {
		.text {
			color: #fff;
			border-left: 4px solid #fff;
		}
	}
}
.module-slideshow-mobile {
	.text {
		color: #323533;
		font-weight: 900;
		font-family: $font1;
		padding-left: 20px;
		span {
			color: #fe0002;
		}
	}
	&.header {
		.text {
			color: #fff;
		}
	}
}

/*****************************************************/

.img-shadow {
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
}

/*****************************************************/

.contact-text { 
	font-size: 24px;
	font-weight: 400;
	font-family: $font2;
	line-height: 140%;
	.phone {
		color: #fe0000;
		font-weight: 900;
	}
	.mail {
		color: #fe0000;
	}
}

/*****************************************************/

.formation-text {
	font-size: 24px;
	font-weight: 400;
	font-family: $font1;
	line-height: 140%;
	span {
		color: #fe0000;
	}
}

.list-formation {
	max-width: 1500px;
	margin: 0 auto;
	.formation-item {
		width: 100%;
		display: block;
		margin-bottom: 30px;
		.nogut {
			padding: 0;
		}
		img {
			float: right;
			background: #323533;
		}
		p {
			top: 50%;
			width: 100%;
			margin: 0;
			display: table;
			text-align: center;
			position: relative;
			@include transform(translateY(-50%));
			@media screen and (min-width: 768px) {
				text-align: left;
			}
		}
		.title {
			float: left;
			color: #323533;
			font-size: 24px;
			font-weight: 400;
			font-family: $font2;
			line-height: 100%;
			text-transform: uppercase;
			display: block;
		}
		.link {
			float: left;
			line-height: 180%;
			color: #fe0002;
			display: block;
		}
	}
}

/*****************************************************/

article {
	padding: 50px 0;
	font-size: 10px;
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	text-align: center;
	h3 {
		color: #323533;
		font-size: 4.8em;
		font-weight: bold;
		font-family: $font2;
		span {
			color: #fe0002;
		}
	}
	p	{
		font-size: 1.8em;
		font-weight: 400;
		font-family: $font1;
		line-height: 140%;
		margin-bottom: 50px;
	}
	img {
		max-width: 90%;
	}
	ul {
		margin: 0;
		padding: 0;
		text-align: left;
		list-style: none;
		li {
			font-size: 1.8em;
			line-height: 200%;
			position: relative;
			span {

			}
			i {
				top: 0;
				left: -30px;
				width: 30px;
				height: 30px;
				position: absolute;
				display: inline-block;
				background-image: url('../images/bullet-liste.png');
			}
		}
	}
}

/*****************************************************/

.module-copyrights {
	padding: 30px 0;
	background-color: #000;
	span {
		color: #808080;
		font-size: 14px;
		font-weight: 400;
		font-family: $font1;
		line-height: 100%;
		img {
			top: -3px;
			left: -4px;
			position: relative;
		}
	}
	.left {
		float: left;
	}
	.right {
		float: right;
	}
	@media screen and (max-width: 767px) {
		.left, .right {
			float: none;
			width: 100%;
			line-height: 120%;
			text-align: center;
		}
	}
}


/*****************************************************/

.machine-item {
	text-align: left;
	position: relative;
	margin-bottom: 30px;
	img {
		margin: 0 auto;
	}
	.title {
		color: #323533;
		font-size: 24px;
		font-weight: 400;
		font-family: $font2;
		line-height: 100%;
		margin-top: 15px;
		margin-bottom: 25px;
	}
	.code {
		color: #323533;
		font-size: 24px;
		font-weight: bold;
		font-family: $font2;
		line-height: 120%;
		margin-bottom: 5px;
	}
	.prix {
		color: #fe0002;
		font-size: 48px;
		font-weight: bold;
		font-family: $font2;
		line-height: 100%;
		margin-bottom: 5px;
	}
	.button {
		width: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		border: 0;
		color: #fff;
		display: block;
		font-size: 24px;
		line-height: 200%;
		text-align: center;
		background: #323533;
		position: relative;
	}
	@media screen and (min-width: 992px) {
		.button {
			position: absolute;
		}
	}
}

/*****************************************************/

.bon-de-commande {
	table {
		border: 0;
		font-size: 18px;
		font-family: Arial;
		tr {
			border: 0;
			background-color: #e6e6e6;
			&:nth-child(1) {
				color: #b3b3b3;
				font-weight: 900;
			}
			&:nth-child(2n+1) {
				background-color: #ffffff;
			}
		}
	}
	@media screen and (max-width: 865px) {
		table {
			font-size: 12px;
			input {
				max-width: 50px;
			}
		}
	}
}
